import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookie from 'js-cookie'
import store from '../store'
import {getSession} from '@/utils/util'
Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    component: (resolve) => require(['@/views/home/Home'], resolve),
    meta: {
      requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
    },
		redirect: '/welcome',
    children: [
      //欢迎登录
      {
        path: '/welcome',
        name: 'welcome',
        component: (resolve) => require(['@/views/welcome/index'], resolve),
        meta: { title: '首页', icon: 'welcome', requireAuth: true,keepAlive:true, closable:false},
      },
      //技能培训
      {
        path: '/tech/fudaoclasstype2',
        name: 'fudaoclasstype2',
        component: (resolve) => require(['@/views/tech/fudaoclasstype2/index'], resolve),
        meta: { title: '技能班别管理', icon: 'fudaoclasstype2', requireAuth: true,keepAlive:true,closable:true },
      },
      {
        path: '/tech/techclass',
        name: 'techclass',
        component: (resolve) => require(['@/views/tech/techclass/index'], resolve),
        meta: { title: '班级管理', icon: 'techclass', requireAuth: true,keepAlive:true,closable:true },
      },
      {
        path: '/tech/techteachingplan',
        name: 'techteachingplan',
        component: (resolve) => require(['@/views/tech/techteachingplan/index'], resolve),
        meta: { title: '教学计划', icon: 'techteachingplan', requireAuth: true,keepAlive:true,closable:true },
      },
      {
        path: '/tech/techteacher',
        name: 'techteacher',
        component: (resolve) => require(['@/views/tech/techteacher/index'], resolve),
        meta: { title: '教师管理', icon: 'techteacher', requireAuth: true,keepAlive:true,closable:true }
      },
      {
        path: '/tech/studentstudy',
        name: 'studentstudy',
        component: (resolve) => require(['@/views/tech/studentstudy/index'], resolve),
        meta: { title: '学生学习状态', icon: 'studentstudy', requireAuth: true,keepAlive:true,closable:true }
      },
      {
        path: '/tech/techexam',
        name: 'techexam',
        component: (resolve) => require(['@/views/tech/techexam/index'], resolve),
        meta: { title: '成绩管理', icon: 'techexam', requireAuth: true,keepAlive:true,closable:true }
      },
      {
        path: '/tech/techcertificate',
        name: 'techcertificate',
        component: (resolve) => require(['@/views/tech/techcertificate/index'], resolve),
        meta: { title: '证书管理', icon: 'techcertificate', requireAuth: true,keepAlive:true,closable:true }
      },
      {
        path: '/tech/technocertificate',
        name: 'technocertificate',
        component: (resolve) => require(['@/views/tech/technocertificate/index'], resolve),
        meta: { title: '未取得证书人员', icon: 'technocertificate', requireAuth: true,keepAlive:true,closable:true }
      },
      //教学管理
      {
        path: '/education/guakaoschool',
        name: 'guakaoschool',
        component: (resolve) => require(['@/views/education/guakaoschool/index'], resolve),
        meta: { title: '函授站学生管理', icon: 'guakaoschool', requireAuth: true,keepAlive:true,closable:true }
      },
      {
        path: '/education/classaddress',
        name: 'classaddress',
        component: (resolve) => require(['@/views/education/classaddress/index'], resolve),
        meta: { title: '开课点学生管理', icon: 'classaddress', requireAuth: true,keepAlive:true,closable:true }
      },
      {
        path: '/education/courseplan',
        name: 'courseplan',
        component: (resolve) => require(['@/views/education/courseplan/index'], resolve),
        meta: { title: '教学计划', icon: 'courseplan', requireAuth: true,keepAlive:true,closable:true }
      },
      {
        path: '/education/attendance',
        name: 'attendance',
        component: (resolve) => require(['@/views/education/attendance/index'], resolve),
        meta: { title: '学生考勤', icon: 'attendance', requireAuth: true,keepAlive:true,closable:true } // 学生考勤
      },
      {
        path: '/education/college',
        name: 'college',
        component: (resolve) => require(['@/views/education/college/index'], resolve),
        meta: { title: '院校管理', icon: 'college', requireAuth: true,keepAlive:true,closable:true }
      },
			{
			  path: '/education/examsettings',
			  name: 'examsettings',
			  component: (resolve) => require(['@/views/education/examsettings/index'], resolve),
			  meta: { title: '组卷管理', icon: 'examsettings', requireAuth: true,keepAlive:true,closable:true }
			},
			{
			  path: '/education/businessexams',
			  name: 'businessexams',
			  component: (resolve) => require(['@/views/education/businessexams/index'], resolve),
			  meta: { title: '业务考核', icon: 'businessexams', requireAuth: true,keepAlive:true,closable:true }
			},
      {
        path: '/education/collegemajor',
        name: 'collegemajor',
        component: (resolve) => require(['@/views/education/collegemajor/index'], resolve),
        meta: { title: '专业管理', icon: 'collegemajor', requireAuth: true, keepAlive:true,closable:true }
      },
			{
			  path: '/education/collegeManagement',
			  name: 'collegeManagement',
			  component: (resolve) => require(['@/views/education/collegeManagement/index'], resolve),
			  meta: { title: '院校专业管理', icon: 'collegeManagement', requireAuth: true, keepAlive:true,closable:true }
			},
      {
        path: '/education/studentmove',
        name: 'studentmove',
        component: (resolve) => require(['@/views/education/studentmove/index'], resolve),
        meta: { title: '教学点学生转出', icon: 'studentmove', requireAuth: true,keepAlive:true,closable:true }
      },
      {
        path: '/education/studentmove1',
        name: 'studentmove1',
        component: (resolve) => require(['@/views/education/studentmove1/index'], resolve),
        meta: { title: '教学点学生转入', icon: 'studentmove1', requireAuth: true,keepAlive:true,closable:true }
      },
      {
        path: '/education/studentregister',
        name: 'studentregister',
        component: (resolve) => require(['@/views/education/studentregister/index'], resolve),
        meta: { title: '学籍管理', icon: 'studentregister', requireAuth: true,keepAlive:true,closable:true }
      },
			{
			  path: '/education/studentuser',
			  name: 'studentuser',
			  component: (resolve) => require(['@/views/education/studentuser/index'], resolve),
			  meta: { title: '考前管理', icon: 'studentuser', requireAuth: true,keepAlive:true,closable:true }
			},
      {
			  path: '/education/videocourse',
			  name: 'videocourse',
			  component: (resolve) => require(['@/views/education/videocourse/index'], resolve),
			  meta: { title: '网课管理', icon: 'videocourse', requireAuth: true,keepAlive:true,closable:true }
			},
      {
			  path: '/education/question',
			  name: 'question',
			  component: (resolve) => require(['@/views/education/question/index'], resolve),
			  meta: { title: '题库管理', icon: 'question', requireAuth: true,keepAlive:true,closable:true }
			},

      {
        path: '/education/book',
        name: 'book',
        component: (resolve) => require(['@/views/education/book/index'], resolve),
        meta: { title: '教材管理', icon: 'book', requireAuth: true,keepAlive:true,closable:true }
      },
      {
        path: '/education/diploma',
        name: 'diploma',
        component: (resolve) => require(['@/views/education/diploma/index'], resolve),
        meta: { title: '毕业证书管理', icon: 'diploma', requireAuth: true,keepAlive:true,closable:true }
      },
      {
        path: '/education/coursemanagement',
        name: 'coursemanagement',
        component: (resolve) => require(['@/views/education/coursemanagement/index'], resolve),
        meta: { title: '课程管理', icon: 'coursemanagement', requireAuth: true,keepAlive:true,closable:true }
      },
      {
        path: '/education/teacherattendance',
        name: 'teacherattendance',
        component: (resolve) => require(['@/views/education/teacherattendance/index'], resolve),
        meta: { title: '老师考勤', icon: 'teacherattendance', requireAuth: true,keepAlive:true,closable:true }
      },
      {
        path: '/education/collegetoint',
        name: 'collegetoint',
        component: (resolve) => require(['@/views/education/collegetoint/index'], resolve),
        meta: { title: '院校介绍', icon: 'collegetoint', requireAuth: true,keepAlive:true,closable:true }
      },
			{
			  path: '/education/techtoint',
			  name: 'techtoint',
			  component: (resolve) => require(['@/views/education/techtoint/index'], resolve),
			  meta: { title: '技能介绍', icon: 'techtoint', requireAuth: true,keepAlive:true,closable:true }
			},
      {
        path: '/education/collegecoporg',
        name: 'collegecoporg',
        component: (resolve) => require(['@/views/education/collegecoporg/index'], resolve),
        meta: { title: '校外教学点管理', icon: 'collegecoporg', requireAuth: true,keepAlive:true,closable:true }
      },
      {
        path: '/education/fudaoclasstype',
        name: 'fudaoclasstype',
        component: (resolve) => require(['@/views/education/fudaoclasstype/index'], resolve),
        meta: { title: '辅导班班别管理', icon: 'fudaoclasstype', requireAuth: true,keepAlive:true,closable:true }
      },
      {
        path: '/education/transaction',
        name: 'transaction',
        component: (resolve) => require(['@/views/education/transaction/index'], resolve),
        meta: { title: '异动管理', icon: 'transaction', requireAuth: true,keepAlive:true,closable:true }
      },
      {
        path: '/education/teacherinfo',
        name: 'teacherinfo',
        component: (resolve) => require(['@/views/education/teacherinfo/index'], resolve),
        meta: { title: '师资库管理', icon: 'teacherinfo', requireAuth: true,keepAlive:true,closable:true }
      },
      {
        path: '/education/examplan',
        name: 'examplan',
        component: (resolve) => require(['@/views/education/examplan/index'], resolve),
        meta: { title: '考试安排表', icon: 'examplan', requireAuth: true,keepAlive:true,closable:true }
      },
      {
        path: '/education/paperstock',
        name: 'paperstock',
        component: (resolve) => require(['@/views/education/paperstock/index'], resolve),
        meta: { title: '试卷库', icon: 'paperstock', requireAuth: true,keepAlive:true,closable:true }
      },
      {
        path: '/education/paperprint',
        name: 'paperprint',
        component: (resolve) => require(['@/views/education/paperprint/index'], resolve),
        meta: { title: '试卷统计', icon: 'paperprint', requireAuth: true,keepAlive:true,closable:true }
      },
      {
        path: '/education/studentexamplan',
        name: 'studentexamplan',
        component: (resolve) => require(['@/views/education/studentexamplan/index'], resolve),
        meta: { title: '学生考试列表', icon: 'studentexamplan', requireAuth: true,keepAlive:true,closable:true } // 学生考试管理
      },
      {
        path: '/education/studentregister2',
        name: 'studentregister2',
        component: (resolve) => require(['@/views/education/studentregister2/index'], resolve),
        meta: { title: '毕业生管理', icon: 'studentregister2', requireAuth: true,keepAlive:true,closable:true } // 毕业学生管理
      },
      {
        path: '/education/graduationdesign',
        name: 'graduationdesign',
        component: (resolve) => require(['@/views/education/graduationdesign/index'], resolve),
        meta: { title: '毕业设计/学位要求', icon: 'graduationdesign', requireAuth: true,keepAlive:true,closable:true }
      },
      {
        path: '/education/academic',
        name: 'academic',
        component: (resolve) => require(['@/views/education/academic/index'], resolve),
        meta: { title: '学位要求', icon: 'academic', requireAuth: true,keepAlive:true,closable:true }
      },
      {
        path: '/education/educationexam',
        name: 'educationexam',
        component: (resolve) => require(['@/views/education/educationexam/index'], resolve),
        meta: { title: '成绩管理', icon: 'educationexam', requireAuth: true,keepAlive:true,closable:true }
      },
      {
        path: '/education/educationexamdetail',
        name: 'educationexamdetail',
        component: (resolve) => require(['@/views/education/educationexamdetail/index'], resolve),
        meta: { title: '补考管理', icon: 'educationexamdetail', requireAuth: true,keepAlive:true,closable:true }
      },
      {
        path: '/education/conformacademic',
        name: 'conformacademic',
        component: (resolve) => require(['@/views/education/conformacademic/index'], resolve),
        meta: { title: '符合学位管理', icon: 'conformacademic', requireAuth: true,keepAlive:true,closable:true }
      },
      {
        path: '/education/academicgraduation',
        name: 'academicgraduation',
        component: (resolve) => require(['@/views/education/academicgraduation/index'], resolve),
        meta: { title: '学位毕业证书管理', icon: 'academicgraduation', requireAuth: true,keepAlive:true,closable:true }
      },
      {
        path: '/education/exempt',
        name: 'exempt',
        component: (resolve) => require(['@/views/education/exempt/index'], resolve),
        meta: { title: '免考申请', icon: 'exempt', requireAuth: true,keepAlive:true,closable:true }
      },
      //仓库管理
      {
        path: '/warehouse/stockmanage',
        name: 'stockmanage',
        component: (resolve) => require(['@/views/warehouse/stockmanage/index'], resolve),
        meta: { title: '库存管理', icon: 'stockmanage', requireAuth: true,keepAlive:true,closable:true } // 库存管理
      },
      {
        path: '/warehouse/warehousing',
        name: 'warehousing',
        component: (resolve) => require(['@/views/warehouse/warehousing/index'], resolve),
        meta: { title: '入库记录', icon: 'warehousing', requireAuth: true,keepAlive:true,closable:true } // 入库记录
      },
      {
        path: '/warehouse/collect',
        name: 'collect',
        component: (resolve) => require(['@/views/warehouse/collect/index'], resolve),
        meta: { title: '领取记录', icon: 'collect', requireAuth: true,keepAlive:true,closable:true } // 领取记录
      },
      {
        path: '/warehouse/inventory',
        name: 'inventory',
        component: (resolve) => require(['@/views/warehouse/inventory/index'], resolve),
        meta: { title: '盘点记录', icon: 'inventory', requireAuth: true,keepAlive:true,closable:true } // 盘点记录
      },
      {
        path: '/warehouse/test',
        name: 'test',
        component: (resolve) => require(['@/views/warehouse/stockmanage/index'], resolve),
        meta: { title: '测试', icon: 'test', keepAlive:true,closable:true } // 测试
      },
      //个人中心
      {
        path: '/personcontent/notice',
        name: 'notice',
        component: (resolve) => require(['@/views/personcontent/notice/index'], resolve),
        meta:
          { title: '消息管理', icon: 'notice', requireAuth: true,keepAlive:true,closable:true }// 消息管理
      },
      //办公管理
      {
        path: '/office/contract',
        name: 'contract',
        component: (resolve) => require(['@/views/office/contract/index'], resolve),
        meta: { title: '合同管理', icon: 'contract', requireAuth: true,keepAlive:true,closable:true }// 合同管理
      },
      {
        path: '/office/meeting',
        name: 'meeting',
        component: (resolve) => require(['@/views/office/meeting/index'], resolve),
        meta: { title: '会议管理', icon: 'meeting', requireAuth: true,keepAlive:true,closable:true }// 会议管理
      },
      {
        path: '/office/techenrollplan',
        name: 'techenrollplan',
        component: (resolve) => require(['@/views/office/techenrollplan/index'], resolve),
        meta: { title: '互动美语招生计划', icon: 'techenrollplan', requireAuth: true,keepAlive:true,closable:true } // 互动美语招生计划
      },

      {
        path: '/office/bulletin',
        name: 'bulletin',
        component: (resolve) => require(['@/views/office/bulletin/index'], resolve),
        meta: { title: '发布通知', icon: 'bulletin', requireAuth: true,keepAlive:false,closable:true } // 文件通知

      },
      {
        path: '/office/bulletinobject',
        name: 'bulletinobject',
        component: (resolve) => require(['@/views/office/bulletinobject/index'], resolve),
        meta: { title: '查看通知', icon: 'bulletinobject', requireAuth: true,keepAlive:true,closable:true } // 互动美语招生计划
      },
      {
        path: '/office/bulletinList',
        name: 'bulletinList',
        component: (resolve) => require(['@/views/office/bulletinList/index'], resolve),
        meta: { title: '通知公告', icon: 'bulletinList', requireAuth: true,keepAlive:true,closable:true } // 通讯录
      },
      {
        path: '/office/fileshare',
        name: 'fileshare',
        component: (resolve) => require(['@/views/office/fileshare/index'], resolve),
        meta: { title: '文件共享', icon: 'fileshare', requireAuth: true,keepAlive:true,closable:true } // 文件共享
      },
      {
        path: '/office/links',
        name: 'links',
        component: (resolve) => require(['@/views/office/links/index'], resolve),
        meta: { title: '友情链接', icon: 'links', requireAuth: true,keepAlive:true,closable:true } // 友情链接
      },
      {
        path: '/office/usergroup',
        name: 'usergroup',
        component: (resolve) => require(['@/views/office/usergroup/index'], resolve),
        meta: { title: '分组管理', icon: 'usergroup', requireAuth: true,keepAlive:true,closable:true } // 分组管理
      },
      {
        path: '/office/notice',
        name: 'notice',
        component: (resolve) => require(['@/views/office/notice/index'], resolve),
        meta: { title: '审批提醒', icon: 'notice', requireAuth: true,keepAlive:true,closable:true } // 审批提醒
      },
      {
        path: '/office/maillist',
        name: 'maillist',
        component: (resolve) => require(['@/views/office/maillist/index'], resolve),
        meta: { title: '通讯录', icon: 'maillist', requireAuth: true,keepAlive:true,closable:true } // 通讯录
      },
      {
        path: '/office/goodsregister',
        name: 'goodsregister',
        component: (resolve) => require(['@/views/office/goodsregister/index'], resolve),
        meta: { title: '教材订购计划', icon: 'goodsregister', requireAuth: true,keepAlive:true,closable:true } // 教材订购计划
      },
      {
        path: '/office/purchase',
        name: 'purchase',
        component: (resolve) => require(['@/views/office/purchase/index'], resolve),
        meta: { title: '办公用品采购计划', icon: 'purchase', requireAuth: true,keepAlive:false,closable:true } // 办公用品采购计划
      },
      {
        path: '/office/schedulemanage',
        name: 'schedulemanage',
        component: (resolve) => require(['@/views/office/schedulemanage/index'], resolve),
        meta: { title: '日程管理', icon: 'schedulemanage', requireAuth: true,keepAlive:true,closable:true } // 日程管理
      },
      {
        path: '/office/scheduleyear',
        name: 'scheduleyear',
        component: (resolve) => require(['@/views/office/scheduleyear/index'], resolve),
        meta: { title: '年度日程', icon: 'scheduleyear', requireAuth: true,keepAlive:true,closable:true } // 年度日程
      },
      {
        path: '/office/askleave',
        name: 'askleave',
        component: (resolve) => require(['@/views/office/askleave/index'], resolve),
        meta: { title: '请假管理', icon: 'askleave', requireAuth: true,keepAlive:false,closable:true } // 请假管理
      },
      //人事管理
      {
        path: '/personnel/corporateculture',
        name: 'corporateculture',
        component: (resolve) => require(['@/views/personnel/corporateculture/index'], resolve),
        meta: { title: '德立文化', icon: 'corporateculture', requireAuth: true,keepAlive:true,closable:true }// 德立文化
      },
      {
        path: '/personnel/dailycover',
        name: 'dailycover',
        component: (resolve) => require(['@/views/personnel/dailycover/index'], resolve),
        meta: { title: '每日封面', icon: 'dailycover', requireAuth: true,keepAlive:true,closable:true }// 每日封面
      },
      {
        path: '/personnel/fivestars',
        name: 'fivestars',
        component: (resolve) => require(['@/views/personnel/fivestars/index'], resolve),
        meta: { title: '五星员工', icon: 'fivestars', requireAuth: true,keepAlive:true,closable:true }// 五星员工
      },
      {
        path: '/personnel/building',
        name: 'building',
        component: (resolve) => require(['@/views/personnel/building/index'], resolve),
        meta: { title: '员工团建', icon: 'building', requireAuth: true,keepAlive:true,closable:true }// 员工团建
      },
      {
        path: '/personnel/exchange',
        name: 'exchange',
        component: (resolve) => require(['@/views/personnel/exchange/index'], resolve),
        meta: { title: '学术交流', icon: 'exchange', requireAuth: true,keepAlive:true,closable:true }// 学术交流
      },
      {
        path: '/personnel/elegant',
        name: 'elegant',
        component: (resolve) => require(['@/views/personnel/elegant/index'], resolve),
        meta: { title: '师生风采', icon: 'elegant', requireAuth: true,keepAlive:true,closable:true }// 师生风采
      },
      {
        path: '/personnel/org',
        name: 'org',
        component: (resolve) => require(['@/views/personnel/org/index'], resolve),
        meta: { title: '组织架构', icon: 'org', requireAuth: true,keepAlive:true,closable:true }// 组织架构
      },
      {
        path: '/personnel/annualmeeting',
        name: 'annualmeeting',
        component: (resolve) => require(['@/views/personnel/annualmeeting/index'], resolve),
        meta: { title: '年会策划', icon: 'annualmeeting', requireAuth: true,keepAlive:true,closable:true }// 年会策划
      },
      {
        path: '/personnel/businessvideo',
        name: 'businessvideo',
        component: (resolve) => require(['@/views/personnel/businessvideo/index'], resolve),
        meta: { title: '业务培训', icon: 'businessvideo', requireAuth: true,keepAlive:true,closable:true },
      },
      {
        path: 'play/video',
        name: 'PlayVideo',
        component: '@/views/personnel/PlayVideo',
        meta: { title: '业务培训', icon: 'PlayVideo', requireAuth: false},
      },
      {
        path: '/personnel/complaint',
        name: 'complaint',
        component: (resolve) => require(['@/views/personnel/complaint/index'], resolve),
        meta: { title: '投诉建议', icon: 'complaint', requireAuth: true,keepAlive:true,closable:true }// 投诉建议
      },
      {
        path: '/personnel/holiday',
        name: 'holiday',
        component: (resolve) => require(['@/views/personnel/holiday/index'], resolve),
        meta: { title: '考勤管理', icon: 'holiday', requireAuth: true,keepAlive:true,closable:true }// 考勤管理
      },
      {
        path: '/personnel/user',
        name: 'user',
        component: (resolve) => require(['@/views/personnel/user/index'], resolve),
        meta: { title: '职员管理', icon: 'user', requireAuth: true,keepAlive:true,closable:true } // 职员管理
      },
      {
        path: '/personnel/userconfirm',
        name: 'userconfirm',
        component: (resolve) => require(['@/views/personnel/userconfirm/index'], resolve),
        meta: { title: '转正申请', icon: 'userconfirm', requireAuth: true,keepAlive:true,closable:true } // 转正申请
      },
      {
        path: '/personnel/usermove',
        name: 'usermove',
        component: (resolve) => require(['@/views/personnel/usermove/index'], resolve),
        meta: { title: '职员异动', icon: 'usermove', requireAuth: true,keepAlive:false,closable:true } // 职员异动
      },
      {
        path: '/personnel/position',
        name: 'position',
        component: (resolve) => require(['@/views/personnel/position/index'], resolve),
        meta: { title: '岗位描述', icon: 'position', requireAuth: true,keepAlive:true,closable:true } // 岗位描述
      },


      // 招聘管理
      {
        path: '/recruit/userapply',
        name: 'userapply',
        component: (resolve) => require(['@/views/recruit/userapply/index'], resolve),
        meta: { title: '人员增补', icon: 'userapply', requireAuth: true,keepAlive:false,closable:true } // 人员增补
      },
      {
        path: '/recruit/person',
        name: 'person',
        component: (resolve) => require(['@/views/recruit/person/index'], resolve),
        meta: { title: '人才库', icon: 'person', requireAuth: true,keepAlive:true,closable:true } // 人才库
      },

      //收入项目
      {
        path: '/income/moneyintype',
        name: 'moneyintype',
        component: (resolve) => require(['@/views/income/moneyintype/index'], resolve),
        meta: { title: '收入类型', icon: 'moneyintype', requireAuth: true,keepAlive:true,closable:true } // 收入类型
      },
      {
        path: '/income/journalaccount2',
        name: 'journalaccount2',
        component: (resolve) => require(['@/views/income/journalaccount2/index'], resolve),
        meta: { title: '收入日记账', icon: 'journalaccount2', requireAuth: true,keepAlive:true,closable:true } // 收入日记账
      },
      {
        path: '/income/moneyintype1',
        name: 'moneyintype1',
        component: (resolve) => require(['@/views/income/moneyintype1/index'], resolve),
        meta: { title: '收入一级项目', icon: 'moneyintype1', requireAuth: true,keepAlive:true,closable:true } // 收入一级项目
      },
      {
        path: '/income/moneyintype2',
        name: 'moneyintype2',
        component: () => import('@/views/income/moneyintype2/index'),
        meta: { title: '收入二级项目', icon: 'moneyintype2', requireAuth: true,keepAlive:true,closable:true } // 收入二级项目
      },
      {
        path: '/income/moneyinitem',
        name: 'moneyinitem',
        component: () => import('@/views/income/moneyinitem/index'),
        meta: { title: '收入明细项目', icon: 'moneyinitem', requireAuth: true,keepAlive:true,closable:true } // 收入明细项目
      },
      {
        path: '/income/shouju',
        name: 'shouju',
        component: () => import('@/views/income/shouju/index'),
        meta: { title: '收据管理', icon: 'shouju', requireAuth: true,keepAlive:true,closable:true } // 收据管理
      },
      //出纳日记账
      {
        path: '/journal/journalaccount',
        name: 'journalaccount',
        component: () => import('@/views/journal/journalaccount/index'),
        meta: { title: '帐户流水账', icon: 'journalaccount', requireAuth: true,keepAlive:true,closable:true } // 帐户流水账
      },
      {
        path: '/journal/registerjournal',
        name: 'registerjournal',
        component: () => import('@/views/journal/registerjournal/index'),
        meta: { title: '日记总账', icon: 'registerjournal', requireAuth: true,keepAlive:true,closable:true } // 日记总账
      },
      {
        path: '/journal/prebalances',
        name: 'prebalances',
        component: () => import('@/views/journal/prebalances/index'),
        meta: { title: '账户期末金额', icon: 'prebalances', requireAuth: true,keepAlive:true,closable:true } // 账户期末金额
      },
      //奖励管理
      {
        path: '/reward/followup',
        name: 'followup',
        component: () => import('@/views/reward/followup/index'),
        meta: { title: '学历个人跟进奖励标准', icon: 'followup', requireAuth: true,keepAlive:true,closable:true } // 学历个人跟进奖励标准
      },
      {
        path: '/reward/referral',
        name: 'referral',
        component: () => import('@/views/reward/referral/index'),
        meta: { title: '学历转介绍或优惠奖励标准', icon: 'referral', requireAuth: true,keepAlive:true,closable:true } // 学历转介绍或优惠奖励标准
      },
      {
        path: '/reward/accountingreward',
        name: 'accountingreward',
        component: () => import('@/views/reward/accountingreward/index'),
        meta: { title: '会计/教师资格奖励标准', icon: 'accountingreward', requireAuth: true,keepAlive:true,closable:true } // 会计/教师资格奖励标准
      },
      {
        path: '/reward/pharmacist',
        name: 'pharmacist',
        component: () => import('@/views/reward/pharmacist/index'),
        meta: { title: '执业药师奖励标准', icon: 'pharmacist', requireAuth: true,keepAlive:true,closable:true } // 执业药师奖励标准
      },
      {
        path: '/reward/activity',
        name: 'activity',
        component: () => import('@/views/reward/activity/index'),
        meta: { title: '活动奖励', icon: 'activity', requireAuth: true,keepAlive:true,closable:true } // 活动奖励
      },
      {
        path: '/reward/guarantee',
        name: 'guarantee',
        component: () => import('@/views/reward/guarantee/index'),
        meta: { title: '学历招生保底计划', icon: 'guarantee', requireAuth: true,keepAlive:true,closable:true } // 学历招生保底计划
      },
      {
        path: '/reward/educationreward',
        name: 'educationreward',
        component: () => import('@/views/reward/educationreward/index'),
        meta: { title: '执业药师目标达成奖励统计', icon: 'educationreward', requireAuth: true,keepAlive:true,closable:true } // 执业药师目标达成奖励统计
      },
      {
        path: '/reward/testtarget',
        name: 'testtarget',
        component: () => import('@/views/reward/testtarget/index'),
        meta: { title: '成考、网教、自考目标达成奖', icon: 'testtarget', requireAuth: true,keepAlive:true,closable:true } //成考、网教、自考目标达成奖
      },
      {
        path: '/reward/accountantteacher',
        name: 'accountantteacher',
        component: () => import('@/views/reward/accountantteacher/index'),
        meta: { title: '会计、教师资格目标达成奖励统计', icon: 'accountantteacher', requireAuth: true,keepAlive:true,closable:true } //会计、教师资格目标达成奖励统计
      },
      {
        path: '/reward/team',
        name: 'team',
        component: () => import('@/views/reward/team/index'),
        meta: { title: '团队突出贡献奖励标准', icon: 'team', requireAuth: true,keepAlive:true,closable:true } //团队突出贡献奖励标准
      },
      {
        path: '/reward/personal',
        name: 'personal',
        component: () => import('@/views/reward/personal/index'),
        meta: { title: '个人突出贡献奖励标准', icon: 'personal', requireAuth: true,keepAlive:true,closable:true } //个人突出贡献奖励标准
      },
      //院校管理
      {
        path: '/colleges/collegesincome',
        name: 'collegesincome',
        component: () => import('@/views/reward/collegesincome/index'),
        meta: { title: '院校收入管理', icon: 'collegesincome', requireAuth: true,keepAlive:true,closable:true }
      },
      //印章管理
      {
        path: '/sealmanage/seal',
        name: 'seal',
        component: () => import('@/views/sealmanage/seal/index'),
        meta: { title: '印章分类', icon: 'seal', requireAuth: true,keepAlive:true,closable:true } // 印章分类
      },
      {
        path: '/sealmanage/sealrecord',
        name: 'sealrecord',
        component: () => import('@/views/sealmanage/sealrecord/index'),
        meta: { title: '印章使用申请', icon: 'sealrecord', requireAuth: true,keepAlive:false,closable:true } // 印章使用申请
      },
      //往来账目
      {
        path: '/contact/collegesreceivable',
        name: 'collegesreceivable',
        component: () => import('@/views/contact/collegesreceivable/index'),
        meta: { title: '集团内部院校往来应收', icon: 'collegesreceivable', requireAuth: true,keepAlive:true,closable:true } // 院校往来应收
      },
      {
        path: '/contact/collegespay',
        name: 'collegespay',
        component: () => import('@/views/contact/collegespay/index'),
        meta: { title: '集团内部院校往来应付', icon: 'collegespay', requireAuth: true,keepAlive:true,closable:true } // 院校往来应付
      },
      {
        path: '/contact/dailyreceivable',
        name: 'dailyreceivable',
        component: () => import('@/views/contact/dailyreceivable/index'),
        meta: { title: '日常往来应收', icon: 'dailyreceivable', requireAuth: true,keepAlive:true,closable:true } // 日常往来应收
      },
      {
        path: '/contact/dailypay',
        name: 'dailypay',
        component: () => import('@/views/contact/dailypay/index'),
        meta: { title: '日常往来应付', icon: 'dailypay', requireAuth: true,keepAlive:true,closable:true } // 日常往来应付
      },
      {
        path: '/contact/summary',
        name: 'summary',
        component: () => import('@/views/contact/summary/index'),
        meta: { title: '往来汇总', icon: 'summary', requireAuth: true,keepAlive:true,closable:true } // 往来汇总
      },
      {
        path: '/contact/baddebt',
        name: 'baddebt',
        component: () => import('@/views/contact/baddebt/index'),
        meta: { title: '坏账管理', icon: 'baddebt', requireAuth: true,keepAlive:true,closable:true } // 坏账管理
      },
      //银行管理
      {
        path: '/bank/account1',
        name: 'account1',
        component: () => import('@/views/bank/account1/index'),
        meta: { title: '现金账户管理', icon: 'account1', requireAuth: true,keepAlive:true,closable:true } // 现金账户管理
      },
      {
        path: '/bank/account2',
        name: 'account2',
        component: () => import('@/views/bank/account2/index'),
        meta: { title: '银行账户管理', icon: 'account2', requireAuth: true,keepAlive:true,closable:true } // 银行账户管理
      },
      //项目管理
			{
			  path: '/projectManagement/cooperation',
			  name: 'cooperation',
			  component: () => import('@/views/projectManagement/cooperation/index'),
			  meta: { title: '合作名单管理', icon: 'cooperation', requireAuth: true,keepAlive:true,closable:true } // 学历继续教育
			},
			{
			  path: '/projectManagement/generalmoneyiecv',
			  name: 'generalmoneyiecv',
			  component: () => import('@/views/projectManagement/generalmoneyiecv/index'),
			  meta: { title: '报其他收费', icon: 'generalmoneyiecv', requireAuth: true,keepAlive:true,closable:true } // 学历继续教育
			},
      {
        path: '/projectManagement/student',
        name: 'student',
        component: () => import('@/views/projectManagement/student/index'),
        meta: { title: '学历继续教育', icon: 'student', requireAuth: true,keepAlive:true,closable:true } // 学历继续教育
      },
      {
        path: '/projectManagement/studentbook',
        name: 'studentbook',
        component: () => import('@/views/projectManagement/studentbook/index'),
        meta: { title: '购买辅导教材', icon: 'studentbook', requireAuth: true,keepAlive:true,closable:true } // 购买辅导教材
      },
      {
        path: '/projectManagement/studentyubaoming',
        name: 'studentyubaoming',
        component: () => import('@/views/projectManagement/studentyubaoming/index'),
        meta: { title: '代预报名管理', icon: 'studentyubaoming', requireAuth: true,keepAlive:true,closable:true } // 代预报名管理
      },
      {
        path: '/projectManagement/studentfudao',
        name: 'studentfudao',
        component: () => import('@/views/projectManagement/studentfudao/index'),
        meta: { title: '学历辅导班管理', icon: 'studentfudao', requireAuth: true,keepAlive:true,closable:true } // 学历辅导班管理
      },
      {
        path: '/projectManagement/techfudao',
        name: 'techfudao',
        component: () => import('@/views/projectManagement/techfudao/index'),
        meta: { title: '教师资格培训', icon: 'techfudao', requireAuth: true,keepAlive:true,closable:true } // 教师资格培训
      },
      {
        path: '/projectManagement/liveabc',
        name: 'liveabc',
        component: () => import('@/views/projectManagement/liveabc/index'),
        meta: { title: '互动美语', icon: 'liveabc', requireAuth: true,keepAlive:true,closable:true } // 互动美语
      },
      {
        path: '/projectManagement/accountrain',
        name: 'accountrain',
        component: () => import('@/views/projectManagement/accountrain/index'),
        meta: { title: '财会培训', icon: 'accountrain', requireAuth: true,keepAlive:true,closable:true } // 财会培训
      },
      {
        path: '/projectManagement/skillstraining',
        name: 'skillstraining',
        component: () => import('@/views/projectManagement/skillstraining/index'),
        meta: { title: '技能培训', icon: 'skillstraining', requireAuth: true,keepAlive:true,closable:true } // 技能培训
      },
      {
        path: '/projectManagement/practice',
        name: 'practice',
        component: () => import('@/views/projectManagement/practice/index'),
        meta: { title: '执业药师培训', icon: 'practice', requireAuth: true,keepAlive:true,closable:true } // 执业药师培训
      },
      //系统设置
      {
        path: '/system/module',
        name: 'module',
        component: () => import('@/views/system/module/index'),
        meta: { title: '模块管理', icon: 'module', requireAuth: true,keepAlive:true,closable:true } // 模块管理
      },
      {
        path: '/system/module',
        name: 'module',
        // component: () => import('@/views/system/module/index'),
        meta: { title: '模块管理', icon: 'module', requireAuth: true,keepAlive:true,closable:true } // 模块管理
      },
      {
        path: '/system/dictype',
        name: 'dictype',
        component: () => import('@/views/system/dictype/index'),
        meta: { title: '字典类型管理', icon: 'dictype', requireAuth: true,keepAlive:true,closable:true } // 字典类型管理
      },
			{
			  path: '/system/approval',
			  name: 'approval',
			  component: () => import('@/views/system/approval/index'),
			  meta: { title: '审核人管理', icon: 'approval', requireAuth: true,keepAlive:true,closable:true } // 审核人管理
			},
      {
        path: '/system/dic',
        name: 'dic',
        component: () => import('@/views/system/dic/index'),
        meta: { title: '字典键值管理', icon: 'dic', requireAuth: true,keepAlive:true,closable:true } // 字典键值管理
      },
      {
        path: '/system/role',
        name: 'role',
        component: () => import('@/views/system/role/index'),
        meta: { title: '角色管理', icon: 'role', requireAuth: true,keepAlive:true,closable:true } // 角色管理
      },
      {
        path: '/system/loginfaillog',
        name: 'loginfaillog',
        component: () => import('@/views/system/loginfaillog/index'),
        meta: { title: '登录失败日志', icon: 'loginfaillog', requireAuth: true,keepAlive:true,closable:true } // 登录失败日志
      },
      {
        path: '/system/oplog',
        name: 'oplog',
        component: () => import('@/views/system/oplog/index'),
        meta: { title: '操作日志', icon: 'oplog', requireAuth: true,keepAlive:true,closable:true } // 操作日志
      },
      {
        path: '/system/dateweek',
        name: 'dateweek',
        component: () => import('@/views/system/dateweek/index'),
        meta: { title: 'XML调整', icon: 'dateweek', requireAuth: true,keepAlive:true,closable:true } // XML调整
      },
      //业绩考核
      {
        path: '/performance/cadmissionsummary',
        name: 'cadmissionsummary',
        component: () => import('@/views/performance/cadmissionsummary/index'),
        meta: { title: '成考招生汇总', icon: 'cadmissionsummary', requireAuth: true,keepAlive:true,closable:true } // 成考招生汇总
      },
      {
        path: '/performance/chengkaopersonal',
        name: 'chengkaopersonal',
        component: () => import('@/views/performance/chengkaopersonal/index'),
        meta: { title: '成考招生精英', icon: 'chengkaopersonal', requireAuth: true,keepAlive:true,closable:true } // 成考招生精英
      },
      {
        path: '/performance/market',
        name: 'market',
        component: () => import('@/views/performance/market/index'),
        meta: { title: '市场部业绩汇总', icon: 'market', requireAuth: true,keepAlive:true,closable:true } // 市场部业绩汇总
      },
      {
        path: '/performance/kefu',
        name: 'kefu',
        component: (resolve) => require(['@/views/performance/kefu/index'], resolve),
        meta: { title: '客服业绩汇总', icon: 'kefu', requireAuth: true,keepAlive:true,closable:true } // 客服业绩汇总
      },
      {
        path: '/performance/meiyuzhaosheng',
        name: 'meiyuzhaosheng',
        component: (resolve) => require(['@/views/performance/meiyuzhaosheng/index'], resolve),
        meta: { title: '互动美语业绩考核', icon: 'meiyuzhaosheng', requireAuth: true,keepAlive:true,closable:true } // 客服业绩汇总
      },
      //收支管理
      {
        path: '/inandout/banktocash',
        name: 'banktocash',
        component: (resolve) => require(['@/views/inandout/banktocash/index'], resolve),
        meta: { title: '取现管理', icon: 'banktocash', requireAuth: true,keepAlive:true,closable:true } // 取现管理
      },
      {
        path: '/inandout/studentregisterfeedetail',
        name: 'studentregisterfeedetail',
        component: (resolve) => require(['@/views/inandout/studentregisterfeedetail/index'], resolve),
        meta: { title: '入学注册', icon: 'studentregisterfeedetail', requireAuth: true,keepAlive:true,closable:true } // 入学注册
      },
      {
        path: '/inandout/cashtobank',
        name: 'cashtobank',
        component: (resolve) => require(['@/views/inandout/cashtobank/index'], resolve),
        meta: { title: '存款管理', icon: 'cashtobank', requireAuth: true,keepAlive:true,closable:true } // 存款管理
      },
      {
        path: '/inandout/moneyiecv',
        name: 'moneyiecv',
        component: (resolve) => require(['@/views/inandout/moneyiecv/index'], resolve),
        meta: { title: '收入登记', icon: 'moneyiecv', requireAuth: true,keepAlive:true,closable:true } // 收入登记
      },
      {
        path: '/inandout/moneypay',
        name: 'moneypay',
        component: (resolve) => require(['@/views/inandout/moneypay/index'], resolve),
        meta: { title: '支出登记', icon: 'moneypay', requireAuth: true,keepAlive:true,closable:true } // 支出登记
      },
			{
			  path: '/inandout/discount',
			  name: 'discount',
			  component: (resolve) => require(['@/views/inandout/discount/index'], resolve),
			  meta: { title: '优惠券管理', icon: 'discount', requireAuth: true,keepAlive:true,closable:true } // 优惠卷管理
			},
      {
        path: '/inandout/banktobank',
        name: 'banktobank',
        component: (resolve) => require(['@/views/inandout/banktobank/index'], resolve),
        meta: { title: '本部转账管理', icon: 'banktobank', requireAuth: true,keepAlive:true,closable:true } // 本部转账管理
      },
      {
        path: '/inandout/studentfeeback',
        name: 'studentfeeback',
        component: (resolve) => require(['@/views/inandout/studentfeeback/index'], resolve),
        meta: { title: '退费管理', icon: 'studentfeeback', requireAuth: true,keepAlive:true,closable:true } // 退费管理
      },
			{
			  path: '/inandout/moneypay2',
			  name: 'moneypay2',
			  component: (resolve) => require(['@/views/inandout/moneypay2/index'], resolve),
			  meta: { title: '费用报销', icon: 'moneypay2', requireAuth: true,keepAlive:true,closable:true } // 费用报销
			},
      {
        path: '/inandout/feerecv',
        name: 'feerecv',
        component: (resolve) => require(['@/views/inandout/feerecv/index'], resolve),
        meta: { title: '欠费催收', icon: 'feerecv', requireAuth: true,keepAlive:true,closable:true } // 退费管理
      },
			{
			  path: '/inandout/registerfeetotal',
			  name: 'registerfeetotal',
			  component: (resolve) => require(['@/views/inandout/registerfeetotal/index'], resolve),
			  meta: { title: '学生缴费汇总', icon: 'registerfeetotal', requireAuth: true,keepAlive:true,closable:true } // 学生缴费汇总
			},

      //薪酬管理
      {
        path: '/salary/wages',
        name: 'wages',
        component: (resolve) => require(['@/views/salary/wages/index'], resolve),
        meta: { title: '基本工资', icon: 'wages', requireAuth: true,keepAlive:true,closable:true } // 基本工资
      },
      {
        path: '/salary/welfare',
        name: 'welfare',
        component: (resolve) => require(['@/views/salary/welfare/index'], resolve),
        meta: { title: '基本福利', icon: 'welfare', requireAuth: true,keepAlive:true,closable:true } // 基本福利
      },
      {
        path: '/salary/special',
        name: 'special',
        component: (resolve) => require(['@/views/salary/special/index'], resolve),
        meta: { title: '特殊福利', icon: 'special', requireAuth: true,keepAlive:true,closable:true } // 特殊福利
      },
      {
        path: '/salary/salary',
        name: 'salary',
        component: (resolve) => require(['@/views/salary/salary/index'], resolve),
        meta: { title: '员工薪资', icon: 'salary', requireAuth: true,keepAlive:true,closable:true } // 员工薪资
      },
      //业务活动
      {
        path: '/business1/business',
        name: 'business',
        component: (resolve) => require(['@/views/business/business/index'], resolve),
        meta: { title: '业务活动', icon: 'business', requireAuth: true,keepAlive:true,closable:true } // 业务活动
      },
      //费用管理
      {
        path: '/cost/studentmoneyrecv',
        name: 'studentmoneyrecv',
        component: (resolve) => require(['@/views/cost/studentmoneyrecv/index'], resolve),
        meta: { title: '收费记录', icon: 'studentmoneyrecv', requireAuth: true,keepAlive:true,closable:true } // 收费记录
      },
      //目标管理
      {
        path: '/m23/userplan',
        name: 'userplan',
        component: (resolve) => require(['@/views/m23/userplan/index'], resolve),
        meta: { title: '个人计划', icon: 'userplan', requireAuth: true,keepAlive:true,closable:true } // 个人计划
      },
      {
        path: '/m23/schoolplan',
        name: 'schoolplan',
        component: (resolve) => require(['@/views/m23/schoolplan/index'], resolve),
        meta: { title: '年度计划', icon: 'schoolplan', requireAuth: true,keepAlive:true,closable:true } // 年度计划
      },
      {
        path: '/m23/englishplan',
        name: 'englishplan',
        component: (resolve) => require(['@/views/m23/englishplan/index'], resolve),
        meta: { title: '互动美语招生计划', icon: 'englishplan', requireAuth: true,keepAlive:true,closable:true } // 互动美语招生计划
      },

      //报名管理

			{
			  path: '/m21/studentpotential',
			  name: 'studentpotential',
			  component: (resolve) => require(['@/views/m21/studentpotential/index'], resolve),
			  meta: { title: '学生资源库', icon: 'studentpotential', requireAuth: true,keepAlive:true,closable:true } // 资源库
			},
			{
			  path: '/m21/studentpotentialnal',
			  name: 'studentpotentialnal',
			  component: (resolve) => require(['@/views/m21/studentpotentialnal/index'], resolve),
			  meta: { title: '潜在学生管理', icon: 'studentpotential', requireAuth: true,keepAlive:true,closable:true } // 资源库
			},
      {
        path: '/report/studentdeli',
        name: 'studentdeli',
        component: (resolve) => require(['@/views/report/studentdeli/index'], resolve),
        meta: { title: '招生报表', icon: 'studentdeli', requireAuth: true,keepAlive:true,closable:true } // 招生报表
      },
      {
        path: '/m21/collegefeestandard',
        name: 'collegefeestandard',
        component: (resolve) => require(['@/views/m21/collegefeestandard/index'], resolve),
        meta: { title: '院校收费标准', icon: 'collegefeestandard', requireAuth: true,keepAlive:true,closable:true } // 院校收费标准
      },
      {
        path: '/m21/stufudaofeestandard',
        name: 'stufudaofeestandard',
        component: (resolve) => require(['@/views/m21/stufudaofeestandard/index'], resolve),
        meta: { title: '技能、辅导班收费标准', icon: 'stufudaofeestandard', requireAuth: true,keepAlive:true,closable:true } // 技能、辅导班收费标准
      },
			{
			  path: '/m21/networkregister',
			  name: 'networkregister',
			  component: (resolve) => require(['@/views/m21/networkregister/index'], resolve),
			  meta: { title: '网上报名管理', icon: 'networkregister', requireAuth: true,keepAlive:true,closable:true } // 网上报名管理
			},
			{
			  path: '/m21/networkregister2',
			  name: 'networkregister2',
			  component: (resolve) => require(['@/views/m21/networkregister2/index'], resolve),
			  meta: { title: '深圳网上报名管理', icon: 'networkregister2', requireAuth: true,keepAlive:true,closable:true } // 网上报名管理
			},
      {
			  path: '/m21/studentgenzong',
			  name: 'studentgenzong',
			  component: (resolve) => require(['@/views/m21/studentgenzong/index'], resolve),
			  meta: { title: '跟进记录', icon: 'networkregister2', requireAuth: true,keepAlive:true,closable:true } // 跟进记录
			},
      //招生管理
      {
        path: '/admissions/enrollplan',
        name: 'enrollplan',
        component: (resolve) => require(['@/views/admissions/enrollplan/index'], resolve),
        meta: { title: '年度院校招生计划', icon: 'enrollplan', requireAuth: true,keepAlive:true,closable:true } // 年度院校招生计划
      },
			//分公司
			{
			  path: '/admissions/companyorg',
			  name: 'companyorg',
			  component: (resolve) => require(['@/views/admissions/companyorg/index'], resolve),
			  meta: { title: '分公司', icon: 'companyorg', requireAuth: true,keepAlive:true,closable:true } // 年度院校招生计划
			},
			//分教学点
			{
			  path: '/admissions/schoolorg',
			  name: 'schoolorg',
			  component: (resolve) => require(['@/views/admissions/schoolorg/index'], resolve),
			  meta: { title: '分教学点', icon: 'schoolorg', requireAuth: true,keepAlive:true,closable:true } // 年度院校招生计划
			},
      //支出项目
      {
        path: '/expenditure/moneyouttype1',
        name: 'moneyouttype1',
        component: (resolve) => require(['@/views/expenditure/moneyouttype1/index'], resolve),
        meta: { title: '支出一级项目', icon: 'moneyouttype1', requireAuth: true,keepAlive:true,closable:true } // 支出一级项目
      },
      {
        path: '/expenditure/moneyouttype2',
        name: 'moneyouttype2',
        component: (resolve) => require(['@/views/expenditure/moneyouttype2/index'], resolve),
        meta: { title: '支出二级项目', icon: 'moneyouttype2', requireAuth: true,keepAlive:true,closable:true } // 支出二级项目
      },
      {
        path: '/expenditure/moneyouttype',
        name: 'moneyouttype',
        component: (resolve) => require(['@/views/expenditure/moneyouttype/index'], resolve),
        meta: { title: '支出类型', icon: 'moneyouttype', requireAuth: true,keepAlive:true,closable:true } // 支出类型
      },
      {
        path: '/expenditure/moneyoutitem',
        name: 'moneyoutitem',
        component: (resolve) => require(['@/views/expenditure/moneyoutitem/index'], resolve),
        meta: { title: '支出明细项目', icon: 'moneyoutitem', requireAuth: true,keepAlive:true,closable:true } // 支出明细项目
      },
      {
        path: '/expenditure/journalaccount3',
        name: 'journalaccount3',
        component: (resolve) => require(['@/views/expenditure/journalaccount3/index'], resolve),
        meta: { title: '支出日记账', icon: 'journalaccount3', requireAuth: true,keepAlive:true,closable:true } // 支出日记账
      },
    ]
  },
  {
    path: '/404',
    component: (resolve) => require(['@/views/404'], resolve),
    hidden: true
  },
  //登录
  {
    path: '/login',
    name: 'login',
    component: (resolve) => require(['@/views/login/index2'], resolve),
  },
  //
  {
    path: '*', // 页面不存在的情况下会跳到404页面，仅对path跳转有效
    redirect: '/404',
    hidden: true
  }
];

const router = new VueRouter({
  routes,
})


router.beforeEach((to, from, next) => {
  if ( !getSession('token') && to.meta.requireAuth) {
    console.log('进入到未登录页 - ',to.fullPath)
    next({
      path:'/login'
    })
  }
  next()
})

export default router
