import { setSession, getSession, findItem } from '@/utils/util'
import {
    main_content,
    main_nav,
    main_menu,
    _module_getmodule,
} from "@/request/api/public";
import { myRequest } from '@/request/index'
import router from '@/router/index'

const state = {

    mainMenu: [], //一级菜单
    secondMenu: [], //二级菜单
    thirdMenu: [], //三级菜单

    mainMenuId: getSession('mainMenuId'),
    secondMenuId: getSession('secondMenuId'),
    thirdMenuId: getSession('thirdMenuId'),

    secondMenuItem: null,
    thirdMenuItem: null,

    endJump: true,
};

const mutations = {
    /*
        1.当存在最低层激活项并刷新侧边栏时，要匹配该激活项所在的菜单位置
        2.当切换tabs时，要匹配该激活项所在侧边栏的位置  
        3.当不存在最低层激活项时，点那个菜单就获取那个菜单的数据，并缓存该菜单的激活id  
        4.每次切换菜单时，实时寻找激活项所在的菜单位置  


        注意：目前侧边栏和tab的激活项是根据菜单的id进行匹配，如果出现跳转有问题或者未匹配等等，可以检查激活项的id是否正确
    */

    //一级菜单 api
    mainContent(state) {
        myRequest(
            {
                url: main_content,
            },
            {
                disableTip: true,
            }
        ).then((res) => {
            if (res.data.code === "200") {
                state.mainMenu = res.data.data;
                this._mutations['aside/getMenuItem'][0]({
                    id: getSession('thirdMenuId'),
                    list: ["thirdMenuId", "secondMenuId", "mainMenuId"],
                    fn: () => {
                        this._mutations['aside/mainNav'][0]((state.mainMenuId))
                    }
                })
            }
        });
    },

    //二级菜单 api
    mainNav(state, id) {
        myRequest(
            {
                url: main_nav,
                data: {
                    parentid: id,
                },
            },
            {
                disableTip: true,
            }
        ).then((res) => {
            if (res.data.code === "200") {
                state.secondMenu = res.data.data;
                this._mutations['aside/getMenuItem'][0]({
                    id: getSession('thirdMenuId'),
                    list: ["thirdMenuId", "secondMenuId"],//这里只需要寻找二级和三级id
                    fn: () => {
                        this._mutations['aside/mainmenu'][0](state.secondMenuId)
                        state.secondMenuItem = findItem(
                            state.secondMenu,
                            "id",
                            state.secondMenuId,
                        );
                    }
                })
            }
        });
    },

    //三级菜单 api
    mainmenu(state, id) {
        myRequest(
            {
                url: main_menu,
                data: {
                    parentid: id,
                },
            },
            {
                disableTip: true,
            }
        ).then((res) => {
            if (res.data.code === "200") {
                state.thirdMenu = res.data.data;
                state.thirdMenuId = getSession("thirdMenuId");
                state.thirdMenuItem = findItem(
                    state.thirdMenu,
                    "id",
                    state.thirdMenuId,
                );
                if (state.thirdMenuItem) {
                    this._mutations['aside/thirdRouteJump'][0]();
                }
            }
        });
    },


    //三级菜单路由跳转 event
    thirdRouteJump(state) {
        state.thirdMenuId = state.thirdMenuItem.id;
        router.push({
            name: state.thirdMenuItem.url,
            params: {
                thirdMenuId: state.thirdMenuId,
            },
        });//每次打开新的tab会初始化一次，详细在mixins.js
    },

    /**
     * desc：获取激活的菜单id api --- 分别获取一，二，三级的菜单id
     * @param {*} state
     * @param {*} obj - id:当前激活最终菜单项；list:所处的位置在哪些菜单下；fn:回调，表示已完成给所有菜单赋值
     */
    getMenuItem(state, obj) {
        if(!obj.id) return  //侧边栏无激活项
        myRequest({
            url: _module_getmodule,
            data: {
                id: obj.id,
            },
        }).then((res) => {
            if (res.data.code === "200") {
                const item = obj.list.splice(0, 1)[0];
                if (!item) return
                state[item] = obj.id;
                setSession(item, obj.id);
                state.endJump = obj.list.length <= 1  //判断是否结束赋值
                if (state.endJump && typeof obj.fn === 'function') {
                    obj.fn()
                }
                if (res.data.data.module?.parentid !== 0) {  //如果未到顶级菜单，则继续查找
                    this._mutations['aside/getMenuItem'][0]({
                        id: res.data.data.module?.parentid,
                        list: obj.list,
                        fn: obj.fn
                    });
                }
            }
        });
    },


    //初始化菜单激活项 event
    initActiveMenu(state, activeName) {
        const activeTabItem = this.state.tab.editableTabs.find(i => i.name === activeName)
        if (activeTabItem) {
            this._mutations['aside/goActiveMenuRoute'][0]()
            this._mutations['aside/assignmentMenuId'][0](activeTabItem) //同步激活菜单项的id
        } else {
            //假设目前只有'首页'不需要关闭
            this.state.tab.editableTabsValue = 'welcome'
            setSession('thirdMenuId',1)
            this._mutations['aside/goOriginRoute'][0]()
        }
    },

    /**
     * desc：给激活的菜单分配id（每当激活菜单发生改变，都需要同步菜单id）
     * @param {*} state 
     * @param {*对象} activeMenuItem - 最终的菜单项
     */
    assignmentMenuId(state, activeMenuItem) {
        this._mutations['aside/getMenuItem'][0]({
            id: activeMenuItem.id,
            list: ["thirdMenuId", "secondMenuId", "mainMenuId"],
            fn: () => {
                this._mutations['aside/mainNav'][0](getSession('mainMenuId')) //匹配tab和侧边栏对应的条目
            }
        })
    },

    //跳转激活的菜单路由 event
    goActiveMenuRoute(state) {
        window.vue.$router.push({
            name: this.state.tab.editableTabsValue,
        });
    },

    //跳转初始路由 event
    goOriginRoute(state) {
        window.vue.$router.push({
            path: '/',
        });
    },

    //找tab激活项 event
    findActiveTabsItem(state, obj) {
        const activeTabItem = this.state.tab.editableTabs.find(i => i.name === obj.activeName)
        if (activeTabItem) {
            obj.success(activeTabItem)
        } else {
            obj.faild()
        }
    },

};

const actions = {
    mainContent: ({ commit },) => {
        commit('mainContent',)
    },
    mainNav: ({ commit }, id) => {
        commit('mainNav', id)
    },
    mainmenu: ({ commit }, id) => {
        commit('mainmenu', id)
    },
    thirdRouteJump: ({ commit },) => {
        commit('thirdRouteJump',)
    },
    getMenuItem: ({ commit }, obj) => {
        commit('getMenuItem', obj)
    },


    initActiveMenu: ({ commit }, activeName) => {
        commit('initActiveMenu', activeName);
    },
    assignmentMenuId: ({ commit }, activeMenuItem) => {
        commit('assignmentMenuId', activeMenuItem);
    },
    goActiveMenuRoute: ({ commit },) => {
        commit('goActiveMenuRoute',);
    },
    goOriginRoute: ({ commit },) => {
        commit('goOriginRoute',);
    },
    findActiveTabsItem: ({ commit }, obj) => {
        commit('findActiveTabsItem', obj);
    },
};

export default {
    namespaced: true,  //允许命名store模块
    state,
    mutations,
    actions
};