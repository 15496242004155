import Vue from 'vue'
import Vuex from 'vuex'
import tab from './modules/tab';
import global from './modules/global';
import login from './modules/login';
import selectData from './modules/selectData';
import aside from './modules/aside';
Vue.use(Vuex);
Vue.config.devtools = true;

export default new Vuex.Store({
  modules : {
    tab,
    global,
    login,
    selectData,
    aside
  },
})
