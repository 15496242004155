import { getSession, setSession } from '@/utils/util'
import { _roleuser_list3 } from '@/request/api/public'
import { myRequest } from '@/request/index'

const state = {
    userinfo: getSession('user') || {},
    isMaxAuth: false,//默认非最高权限
    maxRoleCodes: ['01', '02'],//最高权限 - 用于是否可选分公司
	isMixAuth: false,//默认非最高权限
	minRoleCodes: ['01', '03'],//最高权限 - 用于是否可选教学点
    isSecondaryAuth: false,//次级权限
	secondaryCodes: ['01','02','03','04','11'],// - 用于查看手机号
	isMobileAuth: ['01', '04'],//修改手机号
	cooperative: ['48'], //合作机构
	cooperation: false, //合作机构是否显示
    courseCodes: ['01','02','03','04'],// - 用于选择类型
    isCourseAuth: false, //权限
};

const mutations = {

    //获取登录用户权限
    getLoginUserAuth(state, usercode) {
        myRequest({
            url: _roleuser_list3,
            data: {
                userid: usercode
            }
        }, {
            disableTip: true,
        }).then(res => {
            if (res.data.code === '200') {
                state.isMaxAuth = res.data.data.roleuserlist.some((item, i) => {
                    return item.rolecode === state.maxRoleCodes[i]
                })
                setSession('isMaxAuth', state.isMaxAuth)
				state.isMixAuth = res.data.data.roleuserlist.some((item, i) => {
				    return item.rolecode === state.minRoleCodes[i]
				})
				setSession('isMixAuth', state.isMixAuth)
				state.cooperation = res.data.data.roleuserlist.some((item, i) => {
					return state.cooperative.includes(item.rolecode)
				})
				console.log(state.cooperation, '角色state.cooperation')
				setSession('cooperation', state.cooperation)
                state.isSecondaryAuth = res.data.data.roleuserlist.some((item) => {
					return state.secondaryCodes.includes(item.rolecode)
                });
                setSession('isSecondaryAuth', state.isSecondaryAuth)

                state.isCourseAuth = res.data.data.roleuserlist.some((item) => {
					return state.courseCodes.includes(item.rolecode)
                });
                setSession('isCourseAuth', state.isCourseAuth)
				state.isMobileAuth = res.data.data.roleuserlist.some((item, i) => {
					return state.isMobileAuth.includes(item.rolecode)
				})
				setSession('isMobileAuth', state.isMobileAuth)
            }
        })
    }




};

const actions = {
    getLoginUserAuth: ({ commit }, usercode) => {
        commit('getLoginUserAuth', usercode)
    }

};

export default {
    namespaced: true,  //允许命名store模块
    state,
    mutations,
    actions
};