const login_login = '/login/login.do'; //登录

const logout_logout = '/logout/logout.do'; //退出登录

const main_content = '/main/content.do' //一级菜单

const main_nav = '/main/nav.do' //二级菜单

const main_menu = '/main/menu.do' //三级菜单

const module_treedata = '/module/treedata.do' //模块管理
const module_input = '/module/input.do' //增加修改路由
const module_save = '/module/save.do' //增加修改保存
const module_delete = '/module/delete.do' //删除
const module_enable = '/module/enable.do' //启用，禁用
const module_export = '/module/export.do' //导出路由
const module_exportexcel = '/module/exportexcel.do' //导出
const module_exportsave = '/module/exportsave.do' //导出保存


/**
 * typecode：(majortype-专业类型)，(leveltype-层次)，（studytype-学习形式），（grade-年级），（xuezhi-学制），（nation-名族），（policy-政治面貌），（marry-获取婚否），（edu-学历），（degree-学位），（salary-薪金类型）
 */
//下拉数据
const dic_combox = '/dic/combox.do';// 参数：(String typecode-'')    返回值： list

// 获取银行
const _bank_bankbox = '/bank/bankbox.do'


// 现金账户列表
const _account_dialogcashresult = '/account/dialogcashresult.do'


// 入学注册-学生信息
const _studentregister_dialogresult = '/studentregister/dialogresult.do'


// 获取银行
const _moneyouttype_dialogresult = '/moneyouttype/dialogresult.do'


// 收入类型列表
const _moneyintype_dialogresult = '/moneyintype/dialogresult.do';


// 大岗位获取小岗位
const _positiontype_combox2 = '/positiontype/combox2.do'

// 大岗位获取小岗位
const _positionnumber_getnumber = '/positionnumber/getnumber.do'

// 通过教学点id生成员工编号
const _user_autousercode = '/user/autousercode.do'

// 上传文件自定义方法改变请求数据
const _pictureupload_save = '/pictureupload/save.do'

//文件下载
const _filedownload_save = '/filedownload/save.do'


//文件上传
const _fileupload_save = '/fileupload/save.do'

// 用户
const _user_dialogresult4 = '/user/dialogresult4.do'

// 教师
const _teacherinfo_dialogresult = '/teacherinfo/dialogresult.do'

// 投诉人列表
const _user_dialogresult5 = '/user/dialogresult5.do'

//用户路由页
const _allCompany = '/user/input.do';

//分公司 - list
const _org_combox = '/org/combox.do';//parentid:0

//所有教学点和部门
const _allSchool = '/org/comboxchild.do';//orgid:orgid

//工作岗位
const _orgposition_combox = '/orgposition/combox.do';

//函授站
const _org_comboxguakaoschool = '/org/comboxguakaoschool.do'
// 用户
const _vuser_dialogresult = '/vuser/dialogresult.do'

//收入明细
const _moneyinitem_dialogresult = '/moneyinitem/dialogresult.do'

//收入二级项目
const _moneyintype2_dialogresult = '/moneyintype2/dialogresult.do';

//选择账户
const _account_dialogresult = '/account/dialogresult.do';

//重新获取收据编号
const _shouju_getshoujuinfo = '/shouju/getshoujuinfo.do'

//辅导费用单
const _stufudaofeestandard_dialogresult = '/stufudaofeestandard/dialogresult.do'

//职员管理
const _user_dialogresult = '/user/dialogresult.do'


//支出明细
const _moneyoutitem_dialogresult = '/moneyoutitem/dialogresult.do'

//权限列表
const _module_treeuser = '/module/treeuser.do'  //usercode:员工编号

//登录用户角色权限列表
const _roleuser_list3 = '/roleuser/list3.do' //userid:用户id

//课程名称列表
const _coursemanagement_dialogresult = 'coursemanagement/dialogresult.do'

//院校列表
const _collegemajor_dialogresultall = 'collegemajor/dialogresultall.do'

//院校专业列表
const _enrollplandetail_dialogresult = '/enrollplandetail/dialogresult.do';

//模块列表
const _module_treerole = '/module/treerole.do'

//银行账户
const _account_dialogbankresult = '/account/dialogbankresult.do'

//支出类别
const _moneyouttype2_dialogresult = '/moneyouttype2/dialogresult.do'

//班别类型
const _fudaoclasstype_dialogresult = 'fudaoclasstype/dialogresult.do'

//专业申报
const _collegemajorenroll_dialogresult = 'collegemajorenroll/dialogresult.do'

//获取指定module
const _module_getmodule = '/module/getmodule.do';


//购买教材--教材选择
const _vstockbook_dialogresult2 = 'vstockbook/dialogresult2.do'

//人才列表
const _person_dialog = '/person/dialog.do'


const _org_getorglist = '/org/getorglist.do'     //集团总部组织架构图

const _org_getorglist2 = '/org/getorglist2.do'     // 教学点组织架构图

const _collegefeestandard_dialogresult = '/collegefeestandard/dialogresult.do'   //报读学历-院校

const _collegecoporg_dialogresult = '/collegecoporg/dialogresult.do' //校外教学点 
const _collegesrceivable_checkprintshouju = 'collegesreceivable/checkprintshouju.do'// 检查是否可以打印
export {
    _collegecoporg_dialogresult,
    module_export,
    module_exportexcel,
    module_exportsave,
    module_treedata,
    module_input,
    module_save,
    module_delete,
    module_enable,
    _collegefeestandard_dialogresult,
    _org_getorglist,
    _org_getorglist2,
    _person_dialog,
    main_menu,
    main_nav,
    main_content,
    logout_logout,
    login_login,
    dic_combox,
    _allSchool,
    _allCompany,
    _org_combox,
    _bank_bankbox,
    _user_autousercode,
    _user_dialogresult4,
    _user_dialogresult5,
    _pictureupload_save,
    _orgposition_combox,
    _positiontype_combox2,
    _vstockbook_dialogresult2,
    _moneyouttype_dialogresult,
    _positionnumber_getnumber,
    _moneyinitem_dialogresult,
    _moneyintype2_dialogresult,
    _account_dialogresult,
    _shouju_getshoujuinfo,
    _vuser_dialogresult,
    _teacherinfo_dialogresult,
    _fudaoclasstype_dialogresult,
    _studentregister_dialogresult,
    _stufudaofeestandard_dialogresult,
    _org_comboxguakaoschool,
    _user_dialogresult,
    _moneyoutitem_dialogresult,
    _module_treeuser,
    _roleuser_list3,
    _moneyintype_dialogresult,
    _coursemanagement_dialogresult,
    _collegemajor_dialogresultall,
    _filedownload_save,
    _fileupload_save,
    _module_treerole,
    _account_dialogbankresult,
    _moneyouttype2_dialogresult,
    _account_dialogcashresult,
    _collegemajorenroll_dialogresult,
    _module_getmodule,
    _enrollplandetail_dialogresult,
		_collegesrceivable_checkprintshouju
}