import moment from 'moment'
import store from '@/store/index'
/**
 * 时间格式化
 * @param {*} dateStr 时间戳
 * @param {*} filterSymbol 格式符
 * @returns 
 */
export function formatDate(dateStr, filterSymbol) {
    moment.updateLocale(moment.locale(), { invalidDate: "" }) // 清除时间空值默认
    return moment(dateStr).format(filterSymbol);
}

/**
 * 隐藏手机号中4位
 * @param {*number} phoneNum 手机号
 * @param {*number} followuserid 跟进人id
 * @returns 
 */
export function hideMobile(phoneNum,followuserid) {
    if(store.state.login.isSecondaryAuth || followuserid === store.state.login.userinfo.id) return phoneNum //满足该权限或者是当前登录用户
    return phoneNum.match(/(\d{3})(\d{4})(\d{4})/)
        .slice(1)
        .reduce(function (value, item, index) {
            return index === 1 ? value + "****" : value + item;
        });
}

//隐藏列表的手机号
export function phoneFilter(phoneNum){
   let str =  String(phoneNum),
   strLen = str.slice(-7,-3);
   return str.replace(strLen,"****");
}
//隐藏银行卡
const bankCard = (value) => {
	if (value && value.length > 10 ) {
		return `${value.substring(0, 4)} ${"*".repeat(value.length - 8).replace(/(.{4})/g, `$1 `)}${value.length % 4 ? " " : ""}${value.slice(-4)}`;
	}
	return value;
	
}
//隐藏身份证
const onlyIDcard = (value) => {
	if (value && value.length > 8) {
			return `${"*".repeat(value.length - 8)}${value.length % 4 ? " " : ""}${value.slice(-4)}`;
		}
		return value;
}
export {
	bankCard,
	onlyIDcard,
}