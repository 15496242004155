import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import 'nprogress/nprogress.css';
import './assets/css/kard.scss'
import './assets/css/overall.scss'
import createControl from './utils/createControl.js'
import './utils/dialog.js'
//过滤器
import * as filters from './utils/filter'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

//moment(时间格式化)
import moment from 'moment'
Vue.prototype.$moment = moment;

//echarts
import echarts from 'echarts'
Vue.prototype.$echarts = echarts

//qs
import qs from 'qs'
Vue.prototype.$qs = qs // 引入qs

//打印插件
import Print from 'vue-print-nb'
Vue.use(Print)
//ElementUi
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
Vue.prototype.$url = {
  Freemarker: process.env.VUE_APP_BASE_API,
  upload: process.env.VUE_APP_UPLOAD_BASE_API,
}
Vue.config.productionTip = false;
Vue.config.devtools = true;
window.vue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
