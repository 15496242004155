import { getElementNum } from "@/utils/util";
import router from '@/router/index'
const state = {
    baseUrl: process.env.VUE_APP_BASE_API,//基础请求路径
    requestNum:0,//api并发请求数
    isEndRequest:true,//是否结束请求（全局检测某一时机的请求）
    //index - 1
    searchExtend_1: true,//显示'搜索'的展开按钮
    isSearchExtend_1: false,//是否展开
    searchFormHeight_1: 0,
    searchExtendText_1:'展开',

    btnExtend_1: true,//显示'操作按钮'的展开按钮
    isBtnExtend_1: false,//是否展开
    BtnHeight_1: 0,
    BtnExtendText_1: '展开',
    
    //index - 2
    searchExtend_2: true,//显示'搜索'的展开按钮
    isSearchExtend_2: false,//是否展开
    searchFormHeight_2: 0,
    searchExtendText_2:'展开',
    
    btnExtend_2: true,//显示'操作按钮'的展开按钮
    isBtnExtend_2: false,//是否展开
    BtnHeight_2: 0,
    BtnExtendText_2:'展开',

    keepAliveComs: [],//需要缓存的组件
    isFullscreen: false, // dialog是否全屏
    debounceTimer:null,//
};

const mutations = {
    // dialog放大与缩小
    isExpand(state, bool) {
        state.isFullscreen = !bool;
    },

    //是否展开条件
    extendCondition(state, obj) {
        state[obj.showExtend] = getElementNum(obj.selector) > obj.showItemNum
    },
    //目标的展开 click
    onExtendClick(state, name) {
        state[name] = !state[name]
    },

    //初始化需要缓存组件 
    initKeepAlive(state){
        state.keepAliveComs =  router.options.routes[0].children.filter(i=>i.meta.keepAlive).map(i=>{
            return i.name
        })
    },
    
};

const actions = {
    isExpand({ commit }, bool) {
        commit('isExpand', bool)
    },
    extendCondition: ({ commit }, obj) => {
        commit('extendCondition', obj);
    },
    onExtendClick: ({ commit }, name) => {
        commit('onExtendClick', name);
    },
    initKeepAlive: ({ commit }, obj) => {
        commit('initKeepAlive', obj);
    },
};

export default {
    namespaced: true,  //允许命名store模块
    state,
    mutations,
    actions
};