<template>
  <div id="app">
    <router-view v-if="alive"></router-view>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "App",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      alive: true,
    };
  },
  created() {},
  mounted() {},
  methods: {
    reload() {
      this.alive = false;
      this.$nextTick(() => {
        this.alive = true;
      });
    },
  },
};
</script>

<style lang="scss">
/*全局 按钮样式*/
#app {
  width: 100%;
  height: 100%;
  .el-button {
    padding: 9px 18px;
    border-radius: 2px;
  }
}
.el-dialog {
  .el-button {
    padding: 9px 18px;
    border-radius: 2px;
  }
}
/*进度条样式*/
/*进度条样式*/
html{
#nprogress {
  .bar,
  .spinner {
    z-index: 9999 !important;
  }
  .bar{
    height: 4px;
    background-color: rgb(121, 184, 255);
  }
  .spinner{
    right: 30px;
  }
  .spinner-icon{
    width: 26px;
    height: 26px;
    border: solid 4px transparent;
    border-top-color: rgb(121, 184, 255);
    border-left-color: rgb(121, 184, 255);
  }
}
}
/*el-form-item*/
.el-form {
  .el-form-item {
    display: inline-block;
    margin: 0 0 15px 0;
    input {
      height: 36px;
      line-height: 36px;
      border-radius: 2px;
    }
  }
  .el-form-item__content {
    display: flex;
  }
}
/*el-form-item自适应*/
.form-item-w-auto,.box-w-auto {
  .el-form-item {
    width: auto;
  }
}
.form-item-w-100,.box-w-100 {
  .el-form-item {
    width: 100%;
    vertical-align: top;
  }
}
.form-item-w-50,.box-w-50 {
  .el-form-item {
    width: 50%;
    vertical-align: top;
  }
}
.form-item-w-33,.box-w-33 {
  .el-form-item {
    width: 33%;
    vertical-align: top;
  }
}
.form-item-w-25,.box-w-25 {
  .el-form-item {
    width: 25%;
    vertical-align: top;
  }
}
.form-item-w-20,.box-w-20 {
  .el-form-item {
    width: 20%;
  }
}
.important-page-c {
  height: 100%;
}
/*主层表格 - 搜索的表单样式*/
.search-form {
  display: flex;
  align-items: flex-start;
  padding: 10px;
  .l-w{
    width: 75%;
  }
  .r-w{
    width: 25%;
    display: flex;
    justify-content: flex-end;
  }
  .el-form {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
  }
  .el-form-item {
    margin: 5px 0;
    .el-form-item__label {
      padding: 0 10px;
      white-space: nowrap;
    }
  }
  button {
    margin: 5px 10px 5px 20px;
  }
}
/*按钮操作样式*/
.Btn-w {
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;

  & > div {
    height: 100%;
    align-items: flex-start;
  }

  button {
    margin: 5px 10px;
  }
  .btn-l,
  .btn-r {
    display: flex;
    height: 100%;
  }
  .btn-l {
    width: 80%;
    height: 100%;
    flex-wrap: wrap;
  }
  .btn-r {
    width: 30%;
    justify-content: flex-end;
  }
  .Btn-extend {
    display: flex;
  }
}
/* 按钮风格 */
//淡蓝色
.eaf4ff-btn {
  button {
    background-color: #eaf4ff;
    color: #000;
    border-color: #eaf4ff;
    &:focus,
    &:hover {
      background-color: rgba($color: #eaf4ff, $alpha: 0.8);
      color: rgba($color: #000000, $alpha: 0.8);
      border-color: rgba($color: #eaf4ff, $alpha: 0.8);
    }
  }
}
//深蓝色
.btn-2b5a95 {
  button {
    border-color: #2b5a95;
    background-color: #2b5a95;
    color: #fff;
    &:focus,
    &:hover {
      background-color: rgba($color: #2b5a95, $alpha: 0.9);
      color: rgba($color: #fff, $alpha: 0.9);
      border-color: rgba($color: #2b5a95, $alpha: 0.9);
    }
  }
}
/*选择按钮样式*/
.el-dialog{
  .select-btn{
    margin: 0 0 0 10px;
  }
}


/*2层表格样式*/
.index_2 {
  display: flex;
  align-items: flex-start;
  padding: 10px;
  .el-form {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
  }
  .el-form-item {
    margin: 5px 0;
    .el-form-item__label {
      padding: 0 10px;
      white-space: nowrap;
    }
  }
  button {
    margin: 5px 10px 5px 20px;
  }
  .l-w{
    width: 75%;
  }
  .r-w{
    width: 25%;
    display: flex;
    align-items: center;
  }
}

/*首页表格样式*/
.main-c-w {
  .el-table {
    overflow: auto !important;
    flex: 0.95 !important;
    &::before {
      height: 0;
    }
    &::-webkit-scrollbar {
      width: 6px; // 横向滚动条
      height: 10px; // 纵向滚动条 必写
    }

    &::-webkit-scrollbar-thumb {
      background-color: #9e9e9e;
      border-radius: 3px;
    }
    td,
    th {
      padding: 6px 0;
      // border-bottom: 1px solid #ccc;
    }
  }
}
/*分页*/
.el-pagination {
  margin: 10px 0 0 0;
  .el-pager {
    .active {
      background-color: #2b5a95 !important;
    }
  }
}
/*全局快速新增一行样式*/
.more-form-item-w {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  & > div {
    display: inline-block;
    .el-form-item {
      width: 100%;
    }
  }
  & > .el-form-item {
    display: inline-block;
    .el-form-item {
      width: 100%;
    }
  }
}
/*全局选择框搜索样式*/
.selectSearchForm {
  .el-form {
    display: flex;
    padding: 10px 0;
    .el-input {
      padding: 0 10px;
    }
  }
}

/*图片样式 */
.picture{
  width: 150px;
  height: 150px;
}


// 弹框dialog表头样式
.el-dialog__header {
  background-color: #2b5a95;
}
// 文字
.el-dialog__title {
  color: #fff !important;
}
//放大
.el-icon-full-screen,
.el-icon-copy-document {
  float: right;
  font-size: 20px;
  margin-right: 29px;
  margin-top: 1px;
}
</style>
