import moment from 'moment'
import store from '@/store/index'

/**
 * desc：修改，删除表格校验逻辑
 * @param {*数组} multipleSelection 
 * @param {*字符串} way 
 * @param {*布尔} multiple 
 * @param {*回调函数} callBack 
 */
const selectCheck = (multipleSelection, way, multiple, callBack) => {
    if (!multipleSelection.length) {
        window.vue.$message({
            message: `请选择要${way}的条目`,
            type: "warning",
        });
    } else if (!multiple && multipleSelection.length > 1) {
        window.vue.$message({
            message: `每次最多只能${way}一条数据`,
            type: "warning",
        });
    } else {
        callBack()
    }
}


/**
 * desc：确认提示逻辑
 * @param {*对象} obj
 */
const confirmCallBack = (obj) => {
    window.vue.$confirm(obj.content, obj.title, {
        confirmButtonText: obj.confirmButtonText || '确定',
        cancelButtonText: obj.cancelButtonText || '取消',
        distinguishCancelAndClose: obj.distinguishCancelAndClose,
        type: 'warning',
    }).then(() => {
        obj.success()
    }).catch((action) => {
        action === 'cancel' ? obj.fail() : ''
    });
}

/**
 * desc：信息提示
 * @param {对象} obj 
 */
const messageTip = (obj) => {
    window.vue.$message({
        message: obj.message,
        type: obj.type,
        showClose: obj.showClose || true
    })
}

/**
 * desc：捕获不同res状态的提示信息
 * @param {*object} res 
 * @param {*boolean} disableTip 
 */
const responseTip = (res, disableTip) => {
   if (disableTip || !res.msg) return
    if ( res.success && !/接口调用成功/g.test(res.msg)) {
        messageTip({
            message: res.msg,
            type: 'success'
        })
    } else if(!res.success && res.msg) {
        messageTip({
            message: res.msg,
            type: 'warning'
        })
    }
}

/**
 * desc：生成对象参数，类似qs的stringify()
 * @param {*对象} obj 
 */
const createObjParams = (obj) => {
    let str = "";
    for (let key in obj) {
        str += key + '=' + obj[key] + '' + '&'
    }
    str = str.slice(0, str.length - 1)
    return str //'a=1&b=2&c=3'
}

/**
 * 
 * @param {*日期格式} date 
 * @param {*字符串} format 
 */
const formatDare = (date, format) => {
    if (date) return moment(date).format(format);
}


/**
 * desc：返回某个选择器下的子元素数目
 * @param {*选择器} selector 
 */
const getElementNum = (selector) => {
    if (document.querySelector(selector)) {
        return document.querySelectorAll(selector)[0].children.length
    }
}

/**
 * desc：获取元素样式
 */
const getElementStyle = (selector,prop) => {
    const em = document.querySelector(selector)
    if (em) {
        const elProp = window.getComputedStyle(em)[prop]    //元素属性值
        const regResult = /px|rem/g.exec(elProp)  // 
        const notUnitProp = elProp.replace(regResult, '')   //无单位属性值
        if (isNumber(notUnitProp)) {
            return notUnitProp - 0
        }
        return notUnitProp
    }
}



const getElement = (selector) => {
    const em = document.querySelector(selector)
    if (em) {
        return em;
    }
}


/**
 * 
 * @param {} val 
 * @returns boolean
 */
 const isNumber = (val) => {
    return /^(\d+)$|(^\-[1-9]+$)|(^[0-9]+\.[0-9]+$)|(^\-[0-9]+\.[0-9]+$)/g.test(val) // /(\-)|\d+(\.?\d+)?/
}


/**
 * sessionStorage
 * @param {*} key 
 */
const getSession = (key) => {
    return JSON.parse(sessionStorage.getItem(key))
}
const setSession = (key, val) => {
    return sessionStorage.setItem(key, JSON.stringify(val))
}


/**
 * localStorage
 * @param {*} time：存储的时间，值范围为时间戳（1000 * 5 表示 5秒）
 * @returns 
 */
const getLocal = (key) => {
    const data = JSON.parse(localStorage.getItem(key));
    if (data.expirse !== null && data.expirse < new Date().getTime()) {
        localStorage.removeItem(key);
    } else {
        return data.value;
    }
}

const setLocal = (key, val, time) => {
    const data = {
        value:val,
        expirse:new Date().getTime() + time
    }
    return localStorage.setItem(key, JSON.stringify(data))
}


/**
 * 
 * @param {数组} data 
 * @param {*字符串} key 
 * @param {*字符串} val 
 */
//找某一项 event
const findItem = (data, key, val) => {
    return data.find((i) => i[key] === val);
}

/**
 * desc：函数防抖
 * @param {函数} fn - 回调
 * @param {*数值} wait - 延迟回调
 */
const debounce = (fn, wait) => {
    return function () {
        if (store.state.global.debounceTimer) {
            clearTimeout(store.state.global.debounceTimer)
        }
        store.state.global.debounceTimer = setTimeout(fn, wait)
    }

}



/**
 * 快速创建不同表格面板的属性和方法
 */
class PanelControl {
    constructor(option) {
        option.self[option.dataKey + 'Search'] = {}     //搜索
        option.self[option.dataKey + 'Table'] = {}      //表格
        option.self[option.dataKey + 'Page'] = {        //表格页码
            pageindex: 1,
            pagesize: 10,
        }
        option.self[option.dataKey + 'SelectionChange'] = function () {   //选中表格项
            //console.log('选中表格项')
        }
        option.self[option.dataKey + 'CurrentChange'] = function () {     //切换表格分页
            //console.log('切换表格分页')
        }
    }
}


export {
    isNumber,
    getElementStyle,
    getLocal,
    setLocal,
    selectCheck,
    confirmCallBack,
    messageTip,
    responseTip,
    createObjParams,
    formatDare,
    getElementNum,
    getElement,
    getSession,
    setSession,
    findItem,
    debounce,
    PanelControl,
}