
import { dic_combox, _org_combox, _allSchool } from '@/request/api/public'
import { myRequest } from '@/request/index'

const state = {
    companyList: [],//所有分公司
    schoolList:[],//教学点
}

const mutations = {
    //dic_combox（名族，专业类型，层次等等）
    dic_combox(state, obj) {
        myRequest({
            url: dic_combox,
            data: {
                typecode: obj.typecode
            }
        }).then(res => {
            if (res.data.code === '200') {
                obj.that[obj.list] = res.data.data.list
            }
        })
    },
    //分公司
    org_combox(state) {
        myRequest({
            url: _org_combox,
            data: {
                parentid: 0
            }
        }).then(res => {
            if (res.data.code === '200') {
                state.companyList = res.data.data.list
            }
        })
    },
    //教学点
    org_comboxchild(state, orgid) {
        myRequest({
            url: _allSchool,
            data: {
                orgid: orgid
            }
        }).then(res => {
            if (res.data.code === '200') {
                state.schoolList = res.data.data.list
            }
        })
    },

}

const actions = {
    dic_combox: ({ commit }, obj) => {
        commit('dic_combox', obj)
    },
    org_combox: ({ commit },) => {
        commit('org_combox',)
    },
    org_comboxchild: ({ commit },orgid) => {
        commit('org_comboxchild',orgid)
    },
}


export default {
    namespaced: true,
    state,
    mutations,
    actions,
}