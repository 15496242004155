import { getSession, setSession } from '@/utils/util'
import { myRequest } from '@/request/index'
import {
  _module_getmodule,
} from "@/request/api/public";

const state = {
  editableTabsValue: '',   //tab标识
  editableTabs: [], //存放tab选项卡数据
};

const mutations = {
  addTab(state, tabObj) {
    //一.新增选项卡
    //2.给editableTabs添加tab项
    state.editableTabs.push({
      title: tabObj.tabTitleName,    //tab标题名字
      name: tabObj.tabRouteName,    //tab标识
      icon: tabObj.tabIcon,        //tab图标
      id: tabObj.tabId,
      closable:tabObj.closable  //是否可关闭
    });
    state.editableTabsValue = tabObj.tabRouteName;//4.

    //二.新增选项卡是否有重复，重复则去除
    let last = state.editableTabs.length - 1; //0 1 2
    let len = state.editableTabs.length;    //1 2 3
    for (let i = 0; i < len; i++) {
      //这里判断新增时，最新的项是否和倒数第二项一样（最新项为倒数第一项）
      if (len >= 2 && state.editableTabs[i === last ? last - 1 : i].name === state.editableTabs[last].name) { 
        state.editableTabs[i === last ? last - 1 : i].title = tabObj.tabTitleName;//重置title
        state.editableTabs.splice(last, 1);
        break;
      }
    }
  },
  //点击tab项跳转到对应路由
  getTabRouter(state, item) {
    this._mutations['aside/goActiveMenuRoute'][0]()//跳转激活的菜单路由
    this._mutations['aside/findActiveTabsItem'][0]({
      activeName: item.name,
      success: (activeTabItem) => {
        this._mutations['aside/assignmentMenuId'][0](activeTabItem)
      },
    })
  },
  //关闭tab页
  removeTab(state, targetName) {
    let tabs = state.editableTabs;
    let activeName = state.editableTabsValue;
    if (activeName === targetName) {
      tabs.forEach((tab, index) => {
        if (tab.name === targetName) {
          let nextTab = tabs[index + 1] || tabs[index - 1];
          if (nextTab) {
            activeName = nextTab.name;
          }
        }
      });
    }
    state.editableTabsValue = activeName;
    state.editableTabs = tabs.filter(tab => tab.name !== targetName );

    //关闭当前页
    if (targetName === 'currentTab') {
      for (let i = 0; i < state.editableTabs.length; i++) {
        if (state.editableTabs[i].name === activeName && state.editableTabs[i].closable) {
          state.editableTabs.splice(i, 1);
          if (!state.editableTabs.length) break;
          activeName = state.editableTabs[i ? i - 1 : 0].name;
          state.editableTabsValue = activeName;
        }
      }
    }
    //关闭其它页
    if (targetName === 'orderTabs') {
      state.editableTabs = tabs.filter(tab => tab.name === activeName || !tab.closable);
    }
    //关闭所有页
    if (targetName === 'allTabs') {
      state.editableTabs = tabs.filter(tab => tab.name === targetName || !tab.closable );
    }

    //当editableTabs改变时，实时匹配tab栏和侧边栏的默认激活项，包括路由的跳转
    this._mutations['aside/initActiveMenu'][0](state.editableTabsValue)
  },


 
};

const actions = {
  addTab: ({ commit }, tabObj) => {
    commit('addTab', tabObj);
  },
  removeTab: ({ commit }, targetName) => {
    commit('removeTab', targetName);
  },
  getTabRouter: ({ commit }, item) => {
    commit('getTabRouter', item);
  },

 

};

export default {
  namespaced: true,  //允许命名store模块
  state,
  mutations,
  actions
};